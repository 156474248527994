.scrollDown span:nth-child(2) {
    animation-delay: -0.2s;
}

.scrollDown span:nth-child(3) {
    animation-delay: -0.4s;
}

.scrollDown span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}